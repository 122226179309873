import {timeFormat, numberFormat, ParseFloatFormat} from "@custom/index";

export function tableColumn(that) {
    return [
        {
            type: "selection",
            width: 60,
        },
        {
            type: "index",
            label: "序号",
            width: 60,
        },
        {
            prop: 'district',
            label: "区域",
            width: 250,
        },
        {
            prop: "apartmentName",
            label: "房源名称",
            width: 150,
            render: (h, {row: {apartmentName}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
                        }
                    }
                }, apartmentName);
            },
        },
        {
            prop: "contractDuration",
            label: "合同周期",
            width: 220,
            // render: (h, {row}) => {
            //     const {electContractType, uuid} = row;
            //     return h("el-link", {
            //         props: {type: "primary", underline: false},
            //         on: {
            //             click() {
            //                 if (electContractType === 3) {
            //                     that.$alert('线下签约', '提示', {confirmButtonText: '确定',});
            //                     return;
            //                 }
            //
            //                 that.lookDianZiHeTong(uuid, electContractType,row);
            //             }
            //         }
            //     }, row.contractNum);
            // },
            render: (h, {row,row: {contractDuration, electContract}}) => {
                // alert(electContract)
                if (electContract) {
                    return h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click: () => that.getElectronicContract({...row}),
                        }
                    }, contractDuration);
                }
                return h('span', {
                    style:{
                        fontSize: "14px",
                        fontWeight: 500
                    }
                }, contractDuration);
            },
        },
        /*{
            prop: "contractNum",
            label: "合同编号",
            width: 160,
            render: (h, {row}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click: () => that.getElectronicContract({...row}),
                    }
                }, row.contractNum);
            },
        },*/
        {
            prop: "leasorName",
            label: "租户姓名",
            width: 100,
            render: (h, {row: {leasorName}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click: () => that.$router.push({name: "tenant-management", params: {data: {leasorName}}}),
                    }
                }, leasorName);
            },
        },
        {
            prop: "leasorPhone",
            label: "手机号",
            width: 150,
        },
        {
            prop: "lastPayBillDate",
            label: "最新账单日期",
            width: 150,
            render: (h, {row: {lastPayBillDate}}) => {
                lastPayBillDate = lastPayBillDate ? timeFormat(new Date(lastPayBillDate)) : "";
                return h("span", {}, lastPayBillDate);
            }
        },
        {
            prop: "rentalBillAmount",
            label: "未付房租金额(元)",
            render: (h, {row: {rentalBillAmount}}) => {
                rentalBillAmount = rentalBillAmount | 0;
                rentalBillAmount = ParseFloatFormat(rentalBillAmount);
                return h("span", {
                    style: {
                        color: rentalBillAmount > 20 ? 'red' : ''
                    }
                }, rentalBillAmount);
            },
        },
        {
            prop: "depositBillAmount",
            label: "未付押金金额(元)",
            render: (h, {row: {depositBillAmount}}) => {
                depositBillAmount = depositBillAmount | 0;
                depositBillAmount = ParseFloatFormat(depositBillAmount);
                return h("span", {
                    style: {
                        color: depositBillAmount > 20 ? 'red' : ''
                    }
                }, depositBillAmount);
            },
        },
        {
            prop: "otherBillAmount",
            label: "未付杂费金额(元)",
            render: (h, {row: {otherBillAmount}}) => {
                otherBillAmount = otherBillAmount | 0;
                otherBillAmount = ParseFloatFormat(otherBillAmount);
                return h("span", {
                    style: {
                        color: otherBillAmount > 20 ? 'red' : ''
                    }
                }, otherBillAmount);
            },
        },
        /*{
            prop: "contractStatusName",
            label: "合同状态",
            width: 100,
        },
        {
            prop: "pledgeBillStatusName",
            label: "押金账单状态",
        },*/
        // 2023-12-4-陈守亮-新增表格公寓APP密码有效结束时间与区域
        // 2024/06/03 张晓瑜修改为在线密码有效结束时间和蓝牙密码有效结束时间
        {
            prop: "onlinePasswordDate",
            label: "在线密码有效结束时间",
            width: 100,
            render: (h, {row: {onlinePasswordDate}}) => {
                onlinePasswordDate = onlinePasswordDate ? timeFormat(new Date(onlinePasswordDate)) : "";
                return h("span", {}, onlinePasswordDate);
            }
        },
        {
            prop: "bluePasswordDate",
            label: "蓝牙密码有效结束时间",
            width: 100,
            render: (h, {row: {bluePasswordDate}}) => {
                bluePasswordDate = bluePasswordDate ? timeFormat(new Date(bluePasswordDate)) : "";
                return h("span", {}, bluePasswordDate);            }
        },
        // 2023-12-4-陈守亮-正式环境隐藏电池电量
        {
            prop: "lockElectNum",
            label: "门锁电池",
            render: (h, {row: {lockElectNum}}) => {
                if(lockElectNum === -1)  return '无门锁'
                if(lockElectNum === null)  return '——'
                lockElectNum = lockElectNum | 0;
                return h("span", {
                    style: {
                        color: lockElectNum < 20 ? 'red' : ''
                    }
                }, lockElectNum + '%');
            },
        },
        {
            prop: "lockElectNumSyncDate",
            label: "电池更新时间",
            width: 100,
            render: (h, {row: {lockElectNumSyncDate}}) => {
               if(lockElectNumSyncDate){
                   return  lockElectNumSyncDate ? timeFormat(new Date(lockElectNumSyncDate)) : "";
               }else  {
                   return '——'
               }
            }
        },
        // 2024/03/06 张晓瑜新增 催收时间  最新门锁时间
        {
                prop: "collectionDate",
                label: "催收时间",
                width: 100,
                render: (h, {row: {collectionDate}}) => {
                   if(collectionDate){
                       return  collectionDate ? timeFormat(new Date(collectionDate)) : "";
                   }else  {
                       return '——'
                   }
                }
        },
        // 2024/06/03 张晓瑜新增最新电表抄表时间
        {
            prop: "meterReadingDate",
            label: "最新电表抄表时间",
            width: 100,
            render: (h, {row}) => {
                const {meterReadingDate} = row;
                return meterReadingDate ? timeFormat(new Date(meterReadingDate), "yyyy-MM-dd HH:mm:ss") : "——";
            }
        },
        {
            prop: "lockLogsDate",
            label: "最新门锁时间",
            width: 100,
            render: (h, {row}) => {
                const {lockLogsDate} = row;
                return lockLogsDate ? timeFormat(new Date(lockLogsDate), "yyyy-MM-dd HH:mm:ss") : "——";
            }
        },

        // 2024/06/03 张晓瑜新增门锁状态
        {
            prop: "lockStatus",
            label: "门锁状态",
            width: 100,
            render: (h, {row: {lockStatus}}) => {
                return h("span", {
                    style: {
                        color: lockStatus === '异常' ? 'red' : ''
                    }
                }, lockStatus);
            },
        },
    ];
}

export const billColumns = [
    {
        prop: "billNum",
        label: "账单号",
        width: 180,
    },
    {
        prop: "apartment.community.district",
        label: "区域",
    },
    {
        prop: "apartmentTypeName",
        label: "住房类型",
        width: 80
    },
    {
        prop: "contract.apartment.currentBoundLeasor.type",
        label: "租户类型",
        width: 80
    },
    {
        prop: "typeName",
        label: "收费类型",
        width: 100
    },
    {
        prop: "contract.apartment.name",
        label: "座落",
    },
    {
        prop: "contract.apartment.currentBoundLeasor.name",
        label: "租户姓名",
        width: 80
    },
    {
        prop: "apartment.currentBoundLeasor.tel1",
        label: "租户电话",
        width: 120
    },
    {
        prop: "stateCodeName",
        label: "缴费状态",
        width: 80
    },
    {
        label: "支付渠道",
        width: 80,
        render: (h, {row}) => {
            const {paymentChannel} = row;
            return h("span", paymentChannel ? paymentChannel : "未支付");
        },
    },
    {
        label: "缴费金额(元)",
        prop: "amount",
        width: 100,
        render: (h, {row}) => {
            const {stateCode} = row;
            return h("span", {
                style: {
                    textDecoration: stateCode === 600604 ? 'line-through' : ''
                }
            }, numberFormat(row.amount / 100));
        },
    },
    {
        label: "收款时间",
        width: 150,
        render: (h, {row}) => {
            const {paidDate} = row;
            let date = paidDate ? timeFormat(new Date(row.paidDate), "yyyy-MM-dd HH:mm:ss") : "未缴费";
            return h("span", date);
        },
    },
    {
        label: "账单开始时间",
        width: 100,
        render: (h, {row}) => {
            const {startDate} = row;
            let date = startDate ? timeFormat(new Date(row.startDate)) : "无";
            return h("span", date);
        },
    },
    {
        label: "账单结束时间",
        width: 100,
        render: (h, {row}) => {
            const {endDate} = row;
            let date = endDate ? timeFormat(new Date(row.endDate)) : "无";
            return h("span", date);
        },
    }
];

export const tableColumnUnlockRecord = [
    {
        type: "index",
        label: "编号",
        width: 100
    }, {
        prop: "time",
        label: "开锁时间",
        render: (h, {row}) => {
            // console.log(row)
            return timeFormat(new Date(row.time), "yyyy-MM-dd HH:mm:ss");
        },
    }, {
        prop: "typeDesc",
        label: "开锁方式"
    }
];

// 账单状态
export const optionszhangdDan = [
    {
        value: "0",
        label: "全部",
    },
    {
        value: "1",
        label: "未付账单",
    },
    {
        value: "2",
        label: "未付房租",
    },
    {
        value: "3",
        label: "未付押金",
    },
    {
        value: "4",
        label: "未付杂费",
    },

];
// 门锁电池
export const optionsDianCi = [
    {
        value: "0",
        label: "全部",
    },
    {
        value: "1",
        label: "无门锁",
    },
    {
        value: "2",
        label: "低电量",
    },
    {
        value: "3",
        label: "未获取",
    },
];
// 门锁状态
export const doorLockStatus = [
    {
        value: "",
        label: "全部",
    },
    {
        value: "正常",
        label: "正常",
    },
    {
        value: "异常",
        label: "异常",
    },
];